import React, {ReactNode, Ref} from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {
    FostersGrowthIntroContent,
    FostersGrowthWrapper,
    FostersGrowthItem,
    FostersGrowthItemContent,
    FostersGrowthItemImageWrapper, FostersGrowthBackground
} from "./style";
import {Container} from "../../helpers/styles";
import data from './data.json';
import {CommonSectionProps} from "../../helpers/types";

function FostersGrowth({id}: CommonSectionProps, ref: Ref<HTMLDivElement>) {
    const {t} = useTranslation();
    return (
        <FostersGrowthWrapper id={id} ref={ref}>
            <Container>
                <FostersGrowthBackground src="/images/fosters-growth-bg-0.png" alt="Fosters Growth"/>
                <FostersGrowthBackground src="/images/fosters-growth-bg-1.png" alt="Fosters Growth"/>
                <FostersGrowthBackground src="/images/fosters-growth-bg-2.png" alt="Fosters Growth"/>
                <FostersGrowthIntroContent>
                    <h5>{t("landing.fosters-growth.pre-title") as ReactNode}</h5>
                    <h1>{t("landing.fosters-growth.title") as ReactNode}</h1>
                    <p>{t("landing.fosters-growth.description") as ReactNode}</p>
                </FostersGrowthIntroContent>
                {
                    data.map(({image, title, description}, index) => {
                        return (
                            <FostersGrowthItem key={index}>
                                <FostersGrowthItemContent>
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </FostersGrowthItemContent>
                                <FostersGrowthItemImageWrapper>
                                    <img {...image} loading="lazy"/>
                                </FostersGrowthItemImageWrapper>
                            </FostersGrowthItem>
                        )
                    })
                }
            </Container>
        </FostersGrowthWrapper>
    )
}

export default React.forwardRef(FostersGrowth)