import {styled} from "@linaria/react";
import {CollectionItemStyleTypes} from "./types";
import getSize from "../../helpers/get-size";
import colors from "../../helpers/colors";
import SlashIcon from "./slash-icon";
import {CONTAINER_MAX_SIZE} from "../../helpers/constants";

const IMAGE_WRAPPER_SIZE = getSize(40);

export const PresentCollectionItemImageWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: ${IMAGE_WRAPPER_SIZE}em;
  height: ${IMAGE_WRAPPER_SIZE}em;
  border-radius: ${getSize(10)}em;

  img {
    padding: .5em;
    width: 100%;
    height: auto;
  }
`
// @ts-ignore
export const PresentCollectionItemTitle = styled.h5`
  padding: 0;
  font-size: ${getSize(14)}em;
  font-weight: 600;
  margin-top: ${getSize(20)}em;
  margin-bottom: ${getSize(10)}em;
  svg {
    margin-right: .5em;
  }

  svg {
    @media screen and (min-width: ${CONTAINER_MAX_SIZE}px) {
      margin-left: -.8em;
      transform: translateX(-.5em);
      margin-right: unset;
    }
  }
`

export const PresentCollectionItemDescription = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${getSize(14)}em;
`

export const PresentCollectionItemWrapper = styled.div<CollectionItemStyleTypes>`
  padding: 0;
  margin-bottom: ${getSize(60)}rem;

  ${PresentCollectionItemImageWrapper} {
    background-color: ${({variant}) => {
      if (variant === 'primary') {
        return colors.red100;
      }
      return colors.green300;
    }};
  }

  ${PresentCollectionItemTitle} {
    color: ${({variant}) => {
      if (variant === 'primary') {
        return colors.darkblue900;
      }
      return colors.white;
    }};

    svg {
      path {
        fill: ${({variant}) => {
          if (variant === 'primary') {
            return colors.primary;
          }

          return colors.green900;
        }};
      }
    }
  }

  ${PresentCollectionItemDescription} {
    color: ${({variant}) => {
      if (variant === 'primary') {
        return colors.darkblue100;
      }
      return colors.green100;
    }};
    
    a {
      color: ${({variant}) => {
        if (variant === 'primary') {
          return 'inherit';
        }
        return colors.green900;
      }};
    }
  }
`