import React, { Ref } from 'react';
import { Background1, Background2, HyperledgerIdentusWrapper, InfoCardWrapper } from './style';
import { Container } from '../../helpers/styles';
import { CommonSectionProps } from '../../helpers/types';
import Button from '../button/button';

function HyperledgerIdentus({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  return (
    <HyperledgerIdentusWrapper ref={ref} id={id}>
      <Container>
        <Background1 src="/images/developer-driven-0.png" alt="Developer driven" />
        <Background2 src="/images/developer-driven-1.png" alt="Developer driven" />
        <InfoCardWrapper>
          <img className="logo" src="/images/identus-logo.png" alt="Hyperledger Identus logo" />
          <h1>What is Hyperledger Identus?</h1>
          <p>
            Hyperledger Identus is a platform that enables businesses to integrate decentralized
            identity into applications, supporting W3C specifications for DIDs and verifiable
            credentials, simplifying adoption and accelerating development.
          </p>
          <Button
            as="a"
            href="https://www.hyperledger.org/projects/identus"
            target="_blank"
            variant="hyperledger"
          >
            {'Learn more at Hyperledger >'}
          </Button>
        </InfoCardWrapper>
      </Container>
    </HyperledgerIdentusWrapper>
  );
}

export default React.forwardRef(HyperledgerIdentus);
