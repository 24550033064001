import React from 'react';
import { styled } from '@linaria/react';

const ArrowIconWrappers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & .path {
    fill: #fff;
  }

  &:hover .path {
    fill: #687a84;
  }
`;

export default function ArrowDownIcon() {
  return (
    <ArrowIconWrappers>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 1024 1024"
        className="icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path"
          d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
          fill="#fff"
        />
      </svg>
    </ArrowIconWrappers>
  );
}
