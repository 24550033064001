import { styled } from '@linaria/react';
import getSize from '../../helpers/get-size';
import colors from '../../helpers/colors';
import { MeetTheTeamCardTypes, OverlayButtonTypes, OverlayTypes } from './types';
import {
  CONTAINER_SPACING_Y,
  FONT_18_SIZE,
  FONT_32_SIZE,
  SECTION_PADDING_BOTTOM,
  SECTION_SPACING_Y,
} from '../../helpers/constants';
import { rgba } from 'polished';

export const MeetTheTeamWrapper = styled.section`
  ${SECTION_SPACING_Y};
  background-color: ${rgba(colors.gray100, 0.4)};
  position: relative;
`;

const CONTENT_VERTICAL_SPACING = getSize(22);

export const MeetTheTeamContentWrapper = styled.div`
  padding: 0;
  max-width: ${getSize(700)}em;
  margin-bottom: ${getSize(52)}em;
  h5 {
    font-size: ${getSize(18)}em;
    color: ${colors.primary};
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  h1 {
    ${FONT_32_SIZE};
    color: ${colors.darkblue900};
    font-weight: 600;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  p {
    font-size: ${getSize(18)}em;
    font-weight: 400;
    color: ${colors.darkblue100};
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
`;

export const MeetTheTeamCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 39px 25px -45px rgba(35, 56, 72, 0.5);
  background: url('/images/meet-the-team-card-background.svg');
  background-size: cover;
`;

export const MeetTheTeamCardButton = styled.div<OverlayButtonTypes>`
  max-width: 142.91px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  background-color: ${colors.primary};
  color: ${colors.white};
  margin-left: ${({ variant }) => (variant === 'primary' ? 'auto' : '0')};
  text-align: center;
  font-weight: 600;
  border-radius: 16px 0 16px 0;
  position: relative;
  line-height: 1;

  &:before {
    height: 169%;
    content: '';
    width: 21px;
    background-color: ${colors.primary};
    position: absolute;
    top: ${({ variant }) => (variant === 'primary' ? '8px' : 'unset')};
    bottom: ${({ variant }) => (variant === 'primary' ? 'unset' : '8px')};
    left: ${({ variant }) => (variant === 'primary' ? '-15px' : 'unset')};
    right: ${({ variant }) => (variant === 'primary' ? 'unset' : '-15px')};
    transform: rotate(30deg);
  }
`;

export const MeetTheTeamCardImage = styled.div`
  max-width: 107px;
  width: 100%;
  height: 107px;
  border-radius: 50%;
  margin: 1em auto;
  z-index: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MeetTheTeamCardContent = styled.div`
  text-align: center;

  h3 {
    ${FONT_18_SIZE};
    font-weight: 600;
    color: ${colors.darkblue900};
    margin-bottom: 8px;
  }

  p {
    color: ${colors.darkblue100};
    font-size: 11px;
    margin: 0;
  }
`;

export const MeetTheTeamCardLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  justify-content: center;
  max-width: 127px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${getSize(18)}em;
  margin-bottom: ${getSize(18)}em;
  width: 100%;
  gap: 1em;

  img {
    height: 16px;
    width: auto;
  }

  svg {
    height: 26px;
    width: 26px;
  }
`;

export const MeetTheTeamOverlay = styled.div<OverlayTypes>`
  margin: 0;
  //padding: 0;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: center;
  z-index: 1000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  transition: opacity ease-in-out 0.5s;

  @media (max-width: 768px) {
    padding-top: 5em;
  }
`;

export const MeetTheTeamOverlayContainer = styled.div`
  max-width: 508px;
  flex: 1;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${colors.white};
  height: ${getSize(721)}em;
  position: relative;

  ${MeetTheTeamCard} {
    box-shadow: unset;
    background-color: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const MeetTheTeamGrid = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${getSize(29)}em;
  ${MeetTheTeamCard} {
    border: 1px solid ${colors.gray300};
    flex-basis: 253px;
    max-width: 323px;
    flex-grow: 1;
  }
`;

export const MeetTheTeamCardButtonWrapper = styled.div`
  margin: 0;
  padding: 0;
  height: auto;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

export const MeetTheTeamOverlayContent = styled.div`
  padding: 0 1em 2em 1em;
  max-width: 406px;
  margin: 0 auto;
  height: ${getSize(350)}em;
  overflow: auto;

  p {
    color: ${colors.darkblue100};
    font-size: 1em;
    line-height: 28px;
  }

  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
`;
