import {styled} from "@linaria/react";
import getSize from "../../helpers/get-size";
import colors from "../../helpers/colors";
import {FONT_18_SIZE, FONT_32_SIZE, SECTION_SPACING_BOTTOM} from "../../helpers/constants";

export const WhyAtalaPrismWrapper = styled.div`
  ${SECTION_SPACING_BOTTOM};
  padding: 0;
  position: relative;
  scroll-margin-top: ${getSize(100)}em;
`

const CONTENT_VERTICAL_SPACING = getSize(22);

export const WhyAtalaPrismIntroContent = styled.div`
  padding: 0;
  max-width: ${getSize(700)}em;
  margin-top: ${getSize(44)}rem;
  margin-bottom: ${getSize(52)}em;
  
  h5 {
    font-size: ${getSize(18)}em;
    color: ${colors.primary};
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
  
  h1 {
    ${FONT_32_SIZE};
    color: ${colors.darkblue900};
    font-weight: 600;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
  
  p {
    font-size: ${getSize(18)}em;
    font-weight: 400;
    color: ${colors.darkblue100};
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
`

export const WhyAtalaPrismGrid = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: clamp(${getSize(60 * .5)}em, 5vw, ${getSize(60)}em);
`
