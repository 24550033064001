import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  RareEvoWrapper,
  VideoWrapper,
  Bg2Wrapper,
  FirstSection,
  FirstSectionTitle,
  FirstSectionPunchLine,
  SecondSection,
  SecondSectionInfographicsWrapper,
  SecondSectionSpeakersWrapper,
  ThirdSection,
  Bg2WrapperOverlay,
} from './style';
import Button from '../button/button';
import ArrowDownIcon from './down-arrow';
import Infographic1 from './infographic-1.svg';
import Infographic2 from './infographic-2.svg';
import Infographic3 from './infographic-3.svg';
import Infographic4 from './infographic-4.svg';
import HeadCharles from './head-charles.svg';
import HeadDaniela from './head-daniela.svg';
import HeadDavid from './head-david.svg';
import HeadMike from './head-mike.svg';

import { Speaker } from './speaker';

export default function RareEvo() {
  const { t } = useTranslation();
  return (
    <RareEvoWrapper>
      {/*  Background */}
      <VideoWrapper>
        <video autoPlay loop muted playsInline>
          <source src="/videos/rare-evo-bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoWrapper>
      <Bg2Wrapper>{/*<img src="/images/rare-evo-bg-2.jpg" alt="background image" />*/}</Bg2Wrapper>
      <Bg2WrapperOverlay />

      {/*  Content */}
      <FirstSection>
        <FirstSectionTitle>
          <h1>Join us at RareEVO</h1>
          <p className="subtitle-date">August 15-17, 2024</p>
          <div className="purchase-btn">
            <Button
              as="a"
              href="https://rareevo.io/buy-tickets/"
              target="_blank"
              variant="quaternary"
            >
              Purchase Tickets
            </Button>
          </div>
        </FirstSectionTitle>
        <FirstSectionPunchLine>
          <div className="lines-wrapper">
            <h1>Identity in the age of Blockchain</h1>
            <p className="subtitle-date">Friday, August 16, 2024 at 3PM PDT</p>
            <a className="arrow" href="#hero">
              <ArrowDownIcon />
            </a>
          </div>
        </FirstSectionPunchLine>
      </FirstSection>

      <SecondSection>
        <p>
          Join us for an engaging discussion on the evolving landscape of identity. With the
          convergence of our digital and physical identities, the need for robust security, privacy,
          and ownership is more critical than ever.
        </p>
        <SecondSectionInfographicsWrapper>
          <Infographic1 />
          <Infographic2 />
          <Infographic3 />
          <Infographic4 />
        </SecondSectionInfographicsWrapper>
        <a href="https://www.verizon.com/business/en-gb/resources/reports/dbir/" target="_blank">
          Source: Verizon 2024 Data Breach Investigations Report
        </a>

        <p>
          In response, we present Hyperledger Identus, a pioneering project developed by IOG and
          contributed to the Hyperledger Foundation. Join Charles, David, Daniela, and Mike as they
          explore the project's significance and potential to shape the future of digital identity
          and Web3. Take advantage of this opportunity to be part of the conversation shaping the
          future of identity management.
        </p>

        <SecondSectionSpeakersWrapper>
          <Speaker
            avatar={<HeadCharles />}
            name="Charles Hoskinson"
            title="Co-founder & CEO"
            company="Input | Output"
            position="left"
          />
          <Speaker
            avatar={<HeadDaniela />}
            name="Daniela Barbosa"
            title="Executive Director"
            company="Hyperledger Foundation"
            position="right"
          />
          <Speaker
            avatar={<HeadDavid />}
            name="David Harding"
            title="CEO"
            company="Atala PRISM"
            position="left"
          />
          <Speaker
            avatar={<HeadMike />}
            name="Mike Ward"
            title="Chief Product Officer"
            company="Input | Output"
            position="right"
          />
        </SecondSectionSpeakersWrapper>
      </SecondSection>

      <ThirdSection>
        <p className="join-us">Join us at Rare EVO</p>
        <p className="date">Friday, August 16, 2024 at 3PM PDT</p>
        <Button as="a" href="https://rareevo.io/buy-tickets/" target="_blank" variant="quaternary">
          Purchase Tickets
        </Button>
      </ThirdSection>
    </RareEvoWrapper>
  );
}
