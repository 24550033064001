import React, { ReactNode, Ref } from 'react';
import { WhyAtalaPrismGrid, WhyAtalaPrismIntroContent, WhyAtalaPrismWrapper } from './style';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from '../../helpers/styles';
import data from './data.json';
import PresentCollectionItem from '../present-collection-item/present-collection-item';
import { CommonSectionProps } from '../../helpers/types';
import { JoinCommunity } from '../info-card/join-community';

function WhyAtalaPrism({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  return (
    <WhyAtalaPrismWrapper ref={ref} id={id}>
      <Container>
        <WhyAtalaPrismIntroContent>
          <h5>{t('landing.why-atala-prism.pre-title') as ReactNode}</h5>
          <h1>{t('landing.why-atala-prism.title') as ReactNode}</h1>
          <p>{t('landing.why-atala-prism.description') as ReactNode}</p>
        </WhyAtalaPrismIntroContent>
        <WhyAtalaPrismGrid>
          {data.map((props, index) => (
            <PresentCollectionItem key={index} {...props} variant="primary" />
          ))}
        </WhyAtalaPrismGrid>
        <JoinCommunity />

      </Container>
    </WhyAtalaPrismWrapper>
  );
}

export default React.forwardRef(WhyAtalaPrism);
