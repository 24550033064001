import React from 'react';
import { graphql } from 'gatsby';
import Footer from '../components/footer/footer';
import Page from '../components/page/page';
import Hero from '../components/hero/hero';
import RareEvo from '../components/rare-evo/rare-evo';
import WhyAtalaPrism from '../components/why-atala-prism/why-atala-prism';
import DevelopmentDriven from '../components/development-driven/development-driven';
import FostersGrowth from '../components/fosters-growth/foters-growth';
// import CaseStudies from '../components/case-studies/case-studies';
import MeetTheTeam from '../components/meet-the-team/meet-the-team';
import ReachOut from '../components/reach-out/reach-out';
import LatestContent from '../components/latest-content/latest-content';
import SectionTracking from '../components/section-tracking/section-tracking';
import '../helpers/styles';
import { globals } from '../helpers/styles';
import AboutUs from '../components/about-us/about-us';
import Navbar from '../components/menu/navbar';
import HyperledgerIdentus from '../components/hyperledger-identus/hyperledger-identus';

const Main = () => (
  <div className={globals}>
    <Page seo={{ lang: 'en', title: '', description: '' }}>
      <Navbar />
      <RareEvo />
      <Hero />
      <SectionTracking as={WhyAtalaPrism} id="why" />
      <SectionTracking as={HyperledgerIdentus} id="hyperledger-identus" />
      {/*<SectionTracking as={DevelopmentDriven} id="development-driven" />*/}
      <SectionTracking as={FostersGrowth} id="fosters-growth" />
      {/*<SectionTracking as={CaseStudies} id="case-studies" />*/}
      {/*<SectionTracking as={StartJourney} id="start-journey" />*/}
      {/*<Learn />*/}
      <SectionTracking as={LatestContent} id="blog" />
      <SectionTracking as={MeetTheTeam} id="meet-the-team" />
      <SectionTracking as={AboutUs} id="about-us" />
      <SectionTracking as={ReachOut} id="contact" />
      <Footer />
    </Page>
  </div>
);

export default Main;

export const pageQuery = graphql`
  query {
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
