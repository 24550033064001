import { styled } from '@linaria/react';
import getSize from '../../helpers/get-size';
import colors from '../../helpers/colors';
import { FONT_32_SIZE, SECTION_SPACING_BOTTOM } from '../../helpers/constants';

export const LatestContentWrapper = styled.div`
  ${SECTION_SPACING_BOTTOM};
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  scroll-margin-top: ${getSize(100)}em;
`;

const CONTENT_VERTICAL_SPACING = getSize(22);

export const LatestContentIntroContent = styled.div`
  padding: 0;
  padding-bottom: 100px;
  max-width: ${getSize(700)}em;
  margin-top: ${getSize(44)}rem;
  margin-bottom: ${getSize(52)}em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h5 {
    font-size: ${getSize(18)}em;
    color: ${colors.primary};
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  h1 {
    ${FONT_32_SIZE};
    color: ${colors.darkblue900};
    font-weight: 600;
    margin-bottom: ${getSize(52)}em;
    padding: 1em;
  }

  p {
    font-size: ${getSize(18)}em;
    font-weight: 400;
    color: ${colors.darkblue100};
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
`;

export const WhyAtalaPrismGrid = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: clamp(${getSize(60 * 0.5)}em, 5vw, ${getSize(60)}em);
`;
