import React, { MouseEventHandler, ReactNode, Ref, useCallback, useMemo, useState } from 'react';
import {
  MeetTheTeamContentWrapper,
  MeetTheTeamWrapper,
  MeetTheTeamGrid,
  MeetTheTeamCardButton,
  MeetTheTeamCardContent,
  MeetTheTeamCardImage,
  MeetTheTeamCard,
  MeetTheTeamCardLinks,
  MeetTheTeamOverlay,
  MeetTheTeamOverlayContainer,
  MeetTheTeamCardButtonWrapper,
  MeetTheTeamOverlayContent,
} from './style';
import { Container } from '../../helpers/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import data from './data.json';

// @ts-ignore
import Linkedin from '../../images/linkedin.svg';
// @ts-ignore
import Pinterest from '../../images/pinterest.svg';
// @ts-ignore
import Twitter from '../../images/twitter.svg';
// @ts-ignore
import GitHub from '../../images/github.svg';
import { CommonSectionProps } from '../../helpers/types';
import { GetFunding } from '../info-card/get-funding';
import { InfoCardPositionInTheMiddle } from '../info-card/style';

function MeetTheTeam({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  const [overlayIndexActive, setOverlayIndexActive] = useState<number | null>(null);
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const currentMemberVisible = useMemo(() => {
    return overlayIndexActive !== null ? data.at(overlayIndexActive) : null;
  }, [overlayIndexActive]);
  // @ts-ignore
  const handleClose = useCallback(({ target }) => {
    target.getAttribute('data-close-overlay') && setOverlayVisible(false);
  }, []);

  const handleButtonClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const index = event.currentTarget.dataset.index;
      if (index !== undefined) {
        setOverlayIndexActive(parseInt(index, 10));
        setOverlayVisible(true);
      }
    },
    [overlayIndexActive],
  );

  const handleTransitionEnd = useCallback(() => {
    !overlayVisible && setOverlayIndexActive(null);
  }, [overlayIndexActive, overlayVisible, setOverlayIndexActive]);
  return (
    <MeetTheTeamWrapper ref={ref} id={id}>
      <div style={{ height: 150 }} />
      <InfoCardPositionInTheMiddle>
        <GetFunding />
      </InfoCardPositionInTheMiddle>
      <Container>
        <MeetTheTeamContentWrapper>
          <h5>{t('landing.meet-the-team.pre-title') as ReactNode}</h5>
          <h1>{t('landing.meet-the-team.title') as ReactNode}</h1>
          <p>{t('landing.meet-the-team.description-0') as ReactNode}</p>
        </MeetTheTeamContentWrapper>
        <MeetTheTeamGrid>
          {data.map((item, index) => (
            <MeetTheTeamCard key={index}>
              <MeetTheTeamCardContent>
                <MeetTheTeamCardImage>
                  <img src={item.image.src} alt={item.image.alt} loading="lazy" />
                </MeetTheTeamCardImage>
                <h3>{item.name}</h3>
                <p>{item.role}</p>
              </MeetTheTeamCardContent>
              <MeetTheTeamCardLinks>
                {item.links.twitter && (
                  <a href={item.links.twitter} target="_blank" rel="noopener">
                    {/*<img src={twitter} alt="twitter" loading="lazy" />*/}
                    <Twitter />
                  </a>
                )}

                {item.links.linkedin && (
                  <a href={item.links.linkedin} target="_blank" rel="noopener">
                    {/*<img src={linkedin} alt="linkedin" loading="lazy" />*/}
                    <Linkedin />
                  </a>
                )}

                {item.links.github && (
                  <a href={item.links.github} target="_blank" rel="noopener">
                    {/*<img src={github} alt="github" loading="lazy" />*/}
                    <GitHub />
                  </a>
                )}

                {item.links.pinterest && (
                  <a href={item.links.pinterest} target="_blank" rel="noopener">
                    {/*<img src={pinterest} alt="pinterest" loading="lazy" />*/}
                    <Pinterest />
                  </a>
                )}
              </MeetTheTeamCardLinks>
              <MeetTheTeamCardButton
                variant="primary"
                onClick={handleButtonClick}
                data-index={index}
              >
                More {'>'}
              </MeetTheTeamCardButton>
            </MeetTheTeamCard>
          ))}
        </MeetTheTeamGrid>
      </Container>
      {
        <MeetTheTeamOverlay
          visible={overlayVisible}
          onClick={handleClose}
          onTransitionEnd={handleTransitionEnd}
          data-close-overlay
        >
          <MeetTheTeamOverlayContainer>
            <MeetTheTeamCardButtonWrapper>
              <MeetTheTeamCardButton variant="secondary" onClick={() => setOverlayVisible(false)}>
                Close
              </MeetTheTeamCardButton>
              <MeetTheTeamCard>
                <MeetTheTeamCardImage>
                  <img
                    src={currentMemberVisible?.image.src}
                    alt={currentMemberVisible?.image.alt}
                  />
                </MeetTheTeamCardImage>
                <MeetTheTeamCardContent>
                  <h3>{currentMemberVisible?.name}</h3>
                  <p>{currentMemberVisible?.role}</p>
                </MeetTheTeamCardContent>
                <MeetTheTeamCardLinks>
                  {currentMemberVisible?.links.twitter && (
                    <a href={currentMemberVisible?.links.twitter} target="_blank" rel="noopener">
                      {/*<img src={twitter} alt="twitter" loading="lazy" />*/}
                      <Twitter />
                    </a>
                  )}

                  {currentMemberVisible?.links.linkedin && (
                    <a href={currentMemberVisible.links.linkedin} target="_blank" rel="noopener">
                      {/*<img src={linkedin} alt="linkedin" loading="lazy" />*/}
                      <Linkedin />
                    </a>
                  )}

                  {currentMemberVisible?.links.github && (
                    <a href={currentMemberVisible.links.github} target="_blank" rel="noopener">
                      {/*<img src={github} alt="github" loading="lazy" />*/}
                      <GitHub />
                    </a>
                  )}

                  {currentMemberVisible?.links.pinterest && (
                    <a href={currentMemberVisible?.links.pinterest} target="_blank" rel="noopener">
                      {/*<img src={pinterest} alt="pinterest" loading="lazy" />*/}
                      <Pinterest />
                    </a>
                  )}
                </MeetTheTeamCardLinks>
              </MeetTheTeamCard>
              <MeetTheTeamOverlayContent
                dangerouslySetInnerHTML={{ __html: currentMemberVisible?.bio ?? '' }}
              />
            </MeetTheTeamCardButtonWrapper>
          </MeetTheTeamOverlayContainer>
        </MeetTheTeamOverlay>
      }
    </MeetTheTeamWrapper>
  );
}

export default React.forwardRef(MeetTheTeam);
