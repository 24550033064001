import React from 'react';
import Button from '../button/button';
import { InfoCard } from './info-card';

export function GetFunding() {
  return (
    <InfoCard>
      <h1>
        Get funding through Project Catalyst.
        <span className="hidden-on-xs">
          <br />A public forum for innovation collaboration and decentralized grant funding.
        </span>
      </h1>
      <div style={{ marginTop: '2rem' }} />
      <Button as="a" href="https://projectcatalyst.io/" target="_blank" variant="tertiary">
        {'Learn more >'}
      </Button>
    </InfoCard>
  );
}
