import {StyledAboutUsWrapper, StyledAboutUsBackground, StyledAboutUsPreTitle, StyledAboutUsIntro} from './style';
import {Container} from "../../helpers/styles";
import React, {ReactNode, Ref} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CommonSectionProps} from '../../helpers/types';
import Button from '../button/button';
import {Link} from 'gatsby';
function AboutUs({id}:CommonSectionProps, ref: Ref<HTMLDivElement>) {
  const {t} = useTranslation();
  return (
      <StyledAboutUsWrapper id={id} ref={ref}>
        <Container>
          <StyledAboutUsBackground src="/images/developer-driven-0.png" alt="Developer driven"/>
          <StyledAboutUsBackground src="/images/developer-driven-1.png" alt="Developer driven"/>
          <StyledAboutUsPreTitle>
            {t("landing.about-us.pre-title") as ReactNode}
          </StyledAboutUsPreTitle>
          <StyledAboutUsIntro>
            <h1>{t("landing.about-us.title") as ReactNode}</h1>
            <div dangerouslySetInnerHTML={{__html: t('landing.about-us.content')}}/>
          </StyledAboutUsIntro>
          <Button as="a" href="https://apply.workable.com/io-global" target="_blank" variant="secondary">View current jobs</Button>
        </Container>
      </StyledAboutUsWrapper>
  )
}

export default React.forwardRef(AboutUs)
