import { styled } from '@linaria/react';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;

    &.right {
      flex-direction: row-reverse;
    }
  }

  @media (min-width: 1100px) {
    margin-bottom: -100px;
  
    &.right {
      margin-right: -450px
    }

    &.left {
      margin-left: -450px
    }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  .name {
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
  }

  .title {
    font-size: 25px;
    line-height: 40px;
    font-weight: 300;
    color: rgb(167, 167, 169);
  }

  .company {
    font-size: 25px;
    line-height: 40px;
    font-weight: 600;
    color: rgb(168, 38, 62);
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-top: 0;
  }
`;

export function Speaker({
  avatar,
  name,
  title,
  company,
  position,
}: {
  avatar: React.ReactNode;
  name: string;
  title: string;
  company: string;
  position: 'left' | 'right';
}) {
  return (
    <Wrapper className={position}>
      {avatar}
      <Info className={position}>
        <div className="name">{name}</div>
        <div className="title">{title}</div>
        <div className="company">{company}</div>
      </Info>
    </Wrapper>
  );
}
