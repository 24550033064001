import React, { Ref } from 'react';
import { LatestContentIntroContent, LatestContentWrapper } from './style';
import { CommonSectionProps } from '../../helpers/types';
import Button from '../button/button';

function LatestContent({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  return (
    <LatestContentWrapper ref={ref} id={id}>
      <LatestContentIntroContent>
        <h5>Latest Content</h5>
        <h1>Check out our blog for the latest news, updates, and information!</h1>
        <Button as="a" href="https://atala.mymidnight.blog/" target="_blank" variant="primary">
          {'View all posts >'}
        </Button>
      </LatestContentIntroContent>
    </LatestContentWrapper>
  );
}

export default React.forwardRef(LatestContent);
