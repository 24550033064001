import {styled} from "@linaria/react";
import getSize from "../../helpers/get-size";
import colors from "../../helpers/colors";
import {Container} from "../../helpers/styles";
import {FONT_18_SIZE, FONT_32_SIZE, SECTION_SPACING_Y} from "../../helpers/constants";
import {rgba} from 'polished';

const CONTENT_VERTICAL_SPACING = getSize(22);

export const FostersGrowthWrapper = styled.div`
  ${SECTION_SPACING_Y};
  margin: 0;
  height: auto;
  overflow: hidden;
  position: relative;
  background-color: ${colors.gray100};
  background-color: ${rgba(colors.gray100, .4)};
  
  ${Container} {
    position: relative;
  }
`

export const FostersGrowthBackground = styled.img`
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;

  &:first-child {
    left: -80%;
    top: -5%;
    right: unset;
    bottom: unset;
  }

  &:nth-child(2) {
    right: -55%;
    bottom: -15%;
  }

  &:nth-child(3) {
    left: -15%;
    bottom: 2%;
  }
`

export const FostersGrowthIntroContent = styled.div`
  padding: 0;
  max-width: ${getSize(718)}em;
  margin-bottom: clamp(${getSize(122 * .5)}rem, 5vw, ${getSize(122)}rem);

  h5 {
    font-size: ${getSize(18)}em;
    color: ${colors.primary};
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  h1 {
    ${FONT_32_SIZE};
    color: ${colors.darkblue900};
    font-weight: 600;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  p {
    ${FONT_18_SIZE};
    font-weight: 400;
    color: ${colors.darkblue100};
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
`
const GRID_SPACING = getSize(32);
export const FostersGrowthItem = styled.div`
  display: grid;
  gap: clamp(${GRID_SPACING * .2}em, 5vw, ${GRID_SPACING}em);
  align-items: center;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
  @media (min-width: ${getSize(700)}em) {
    grid-template-columns: 1fr 1fr;
  }
`

export const FostersGrowthItemContent = styled.div`
  margin: 0;

  h2 {
    ${FONT_32_SIZE};
    font-weight: 600;
    margin-bottom: ${getSize(18)}rem;
    color: ${colors.darkblue900};
  }

  p {
    ${FONT_18_SIZE};
    color: ${colors.darkblue100};
    font-weight: 400;
  }

`

export const FostersGrowthItemImageWrapper = styled.div`
  margin: 0;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 39px 25px -45px ${colors.black};

  img {
    width: 100%;
    height: auto;
  }

`
