import {styled} from "@linaria/react";
import getSize from "../../helpers/get-size";
import colors from "../../helpers/colors";
import {Container} from "../../helpers/styles";
import {FONT_18_SIZE, FONT_32_SIZE, SECTION_SPACING_Y} from "../../helpers/constants";

export const StyledAboutUsWrapper = styled.section`
  margin: 0;
  background-color: ${colors.darkblue900};
  overflow: hidden;
  ${SECTION_SPACING_Y};
  
  ${Container} {
    position: relative;
  }
`

const CONTENT_VERTICAL_SPACING = getSize(22);
const MEDIA_BREAK_AT = getSize(700);

export const StyledAboutUsPreTitle = styled.h5`
  font-size: ${getSize(18)}em;
  color: ${colors.green900};
  font-weight: 600;
  line-height: 1;
  margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
`

export const StyledAboutUsBackground = styled.img`
  position: absolute;
  right: -43%;
  bottom: -66%;
  z-index: 0;
  pointer-events: none;
  &:first-child {
    left: -37%;
    top: -86%;
    right: unset;
    bottom: unset;
  }
`

export const StyledAboutUsIntro = styled.div`
  padding: 0;
  margin-bottom: ${getSize(52)}em;
  a {
    color: ${colors.green900};
  }
  h1 {
    ${FONT_32_SIZE};
    color: ${colors.white};
    font-weight: 600;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
  
  p {
    font-size: ${getSize(14)}em;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.green100};
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
`
