import { styled } from '@linaria/react';
import colors from '../../helpers/colors';
import getSize from '../../helpers/get-size';

export const RareEvoWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  height: 720px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1; // above hero image
  background: rgba(0, 0, 0, 0.98);
`;

export const VideoWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

  video {
    width: 100%;
    max-height: 720px;
    min-height: 720px;
    object-fit: cover;
  }
`;

export const Bg2Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 720px;
  background-image: url('/images/rare-evo-bg-2.jpg');
  height: 2362px;
  background-repeat: no-repeat;
  //background-size: calc(100%);
  background-size: auto; // Maintain original size
  background-position: center; // Center the image
  z-index: -1;
`;

export const Bg2WrapperOverlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  background-image: url('/images/rare-evo-bg-overlay.svg');
  height: 2362px;
  background-repeat: no-repeat;
  background-size: calc(99%);
  opacity: 0.1;
  z-index: 1;
  display: none;

  @media screen and (min-width: 900px) {
    display: block;
  }
`;

export const FirstSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 4em;

  color: ${colors.white};
  line-height: ${getSize(24)}rem;

  @media screen and (min-width: 700px) {
    padding-top: 8em;

    display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'title .'
      '. content';
  }
`;

export const FirstSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;

  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  h1 {
    font-size: ${getSize(50)}rem;
    line-height: ${getSize(40)}rem;
    font-weight: 600;
    margin-top: 0;
    animation: slideInFromLeft 0.6s ease-out;
  }

  .subtitle-date {
    font-size: ${getSize(40)}rem;
    line-height: ${getSize(40)}rem;
    font-weight: 300;
    margin-top: 0;
    animation: slideInFromLeft 0.8s ease-out;
  }

  .purchase-btn {
    animation: slideInFromLeft 1s ease-out;
  }

  @media screen and (min-width: 700px) {
    grid-area: title;
    justify-self: end;
    align-items: unset;

    * {
      text-align: right;
    }
  }
`;

export const FirstSectionPunchLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  .lines-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    padding-top: 2em;
    text-align: center;
  }

  h1 {
    font-size: ${getSize(32)}rem;
    font-weight: 600;
    line-height: ${getSize(40)}rem;
    animation: slideInFromBottom 0.6s ease-out;
  }

  .subtitle-date {
    font-size: ${getSize(32)}rem;
    font-weight: 300;
    line-height: ${getSize(40)}rem;
    animation: slideInFromBottom 0.8s ease-out;
  }

  .arrow {
    z-index: 1;
    animation: slideInFromBottom 1s ease-out;
  }

  @keyframes slideInFromBottom {
    from {
      transform: translateY(200%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media screen and (min-width: 700px) {
    grid-area: content;

    .lines-wrapper {
      display: inline-block;
      text-align: right;
    }

    h1 {
      font-size: ${getSize(40)}rem;
      font-weight: 600;
    }

    .subtitle-date {
      font-size: ${getSize(40)}rem;
      text-align: right;
    }

    .arrow {
      display: flex;
      justify-content: flex-end;
      position: relative;
    }
  }
`;

export const SecondSection = styled.section`
  color: ${colors.white};
  font-size: ${getSize(25)}rem;
  line-height: ${getSize(35)}rem;
  font-weight: 400;
  text-align: center;
  padding-bottom: 32px;

  p {
    margin-left: 10%;
    margin-right: 10%;
  }

  a {
    font-size: ${getSize(15)}rem;
    line-height: ${getSize(22.5)}rem;
    font-style: italic;
    text-decoration: none;
    color: gray;
    margin-top: 2em;
    display: block;
    width: 100%;
    text-align: center;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: 700px) {
    font-size: ${getSize(35)}rem;
    line-height: ${getSize(50)}rem;
    text-align: left;
  }
`;

export const SecondSectionInfographicsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;
`;

export const SecondSectionSpeakersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2em;

  @media (min-width: 768px) {
    gap: 0;
  }
`;

export const ThirdSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2em;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0) 0%,
    rgba(0, 0, 0, 0.91) 20%,
    rgb(51, 56, 81) 50%,
    rgb(51, 56, 81) 100%
  );
  color: white;
  padding: 32px 16px;

  p {
    margin: 0;
    margin-bottom: 12px;
  }

  .join-us {
    font-size: ${getSize(35)}rem;
    font-weight: 600;
    line-height: ${getSize(40)}rem;
  }

  .date {
    font-size: ${getSize(30)}rem;
    font-weight: 300;
    line-height: ${getSize(40)}rem;
  }

  @media (min-width: 500px) {
    .join-us {
      font-size: ${getSize(50)}rem;
    }

    .date {
      font-size: ${getSize(40)}rem;
    }
  }
  @media (min-width: 1100px) {
    margin-top: 100px;
  }
`;
